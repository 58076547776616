<template>
  <div class="show-teacher-request">
    <loading v-if="isLoading" />
    <b-card v-else>
      <b-row class="conti">
        <b-col class="col-lg-6 col-md-12">
          <h4>
            {{ $t("teacherRequests.personalInfo") }}
          </h4>
          <div
            v-for="item of Object.keys(data.personal)"
            :key="item.id"
            class="section"
          >
            <div class="custom-row" v-if="data.personal[item]">
              -
              <span class="key"> {{ $t(`teacherRequests.${item}`) }} : </span>
              <span class="value"> {{ data.personal[item] }} </span>
            </div>
          </div>
        </b-col>
        <b-col class="col-lg-6 col-md-12">
          <h4>
            {{ $t("teacherRequests.careerInfo") }}
          </h4>
          <div
            v-for="item of Object.keys(data.career)"
            :key="item.id"
            class="section"
          >
            <div class="custom-row" v-if="data.career[item]">
              -
              <span class="key"> {{ $t(`teacherRequests.${item}`) }} : </span>
              <span v-if="item === 'cv'" class="value">
                <a :href="data.career[item].path" target="_blank">
                  {{ $t("teacherRequests.viewAttachment") }}</a
                >
              </span>
              <span v-else class="value">
                {{
                  typeof data.career[item] === "function"
                    ? data.career[item]()
                    : data.career[item]
                }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="text-right">
        <b-button
          class="mr-1"
          variant="outline-primary"
          @click="reject($route.params.id)"
        >
          {{ $t("teacherRequests.reject") }}
        </b-button>
        <b-button variant="primary" @click="acceptTeacher($route.params.id)">
          {{ $t("teacherRequests.accept") }}
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "ShowTeacherRequest",
  components: { Loading, BCard, BCol, BRow, BButton },
  data() {
    return {
      isLoading: false,
      data: {},
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getRequestData(this.$route.params.id);
    }
  },
  methods: {
    async getRequestData(_currentID) {
      this.isLoading = true;
      try {
        const request = await this.$http.get(`admin/teachers/${_currentID}`);
        this.data = this.handelData(request.data.data);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    handelData(data) {
      const newObj = {
        career: {
          experience: data.experience,
          eduLevel: data.EduLevel.name,
          eduYear: () => {
            const eduYear = [];
            for (const item of data.EduYears) {
              eduYear.push(item.name);
            }
            return eduYear.join(", ");
          },
          subject: () => {
            const Subjects = [];
            for (const item of data.Subjects) {
              Subjects.push(item.name);
            }
            return Subjects.join(", ");
          },
          cv: data.user.media?.filter(
            (itemInMedia) => itemInMedia.name === "cv"
          )[0],
        },
        personal: {
          name: `${data.user.first_name} ${data.user.last_name}`,
          email: data.user.email,
          phone: data.user.phone[0].phone,
          nationality: data.user.nationality.name,
          birthDate: data.user.birthdate,
        },
      };
      return newObj;
    },
    async acceptTeacher(id) {
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append("is_approved", 1);
        const request = await this.$http.post(
          `admin/teachers/${id}?_method=put`,
          formData
        );
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast(
            "success",
            request.data.message,
            request.data.message
          );
          this.isLoading = false;
          setTimeout(() => {
            this.$router.push({ name: "teachersRequests" });
          }, 100);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    reject(id) {
      this.isLoading = true;
      this.$http
        .delete(`admin/teachers/${id}`)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.$helpers.makeToast(
              "success",
              response.data.message,
              response.data.message
            );
            this.isLoading = false;
            setTimeout(() => {
              this.$router.push({ name: "teachersRequests" });
            }, 100);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((this.isLoading = false));
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.getRequestData(newValue.params.id);
        }
      },
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
